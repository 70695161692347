<template>
    <transition name="fade">
        <div class="home h1" v-if="loaded" v-on:click="onBackgroundTextClicked()">
            <div class="wrapper-text text-top">
                <page-content :blocks="content"></page-content>
            </div>
            <div class="wrapper-text">
                <page-content :blocks="content"></page-content>
            </div>
            <transition name="fade">
                <div class="wrapper-imgs" :class="{show: show}" v-click-away="onHomeClicked">
                    <router-link :to="{ name: 'Film', params: { slug: film.slug } }" :class="'project-preview project-preview-' + index" v-for="(film, index) in this.$store.getters.getFilms" :key="film.slug"  @mouseover="onPreviewMouseOver($event)" @mouseleave="onPreviewMouseLeave($event)" @mousemove="onPreviewMouseMove($event)" @mousedown="onPreviewMouseDown($event)">
                        <img :src="film.previewImage" :alt="film.title">
                    </router-link>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>

import PageContent from '@/components/PageContentComponent.vue';

export default {
    name: 'Home',
    components: {
        PageContent
    },
    data () {
        return {
            show: true,
            showMenu: true,
            loaded: false,
            lastTimeoutId: 0
        };
    },
    computed: {
        // a computed getter
        content() {
            if (this.$store.getters.getPageBySlug('filmallmende-1'))
                return this.$store.getters.getPageBySlug('filmallmende-1').blocks;
            else
                return [];
        }
    },
    props: {},
    mounted(){
        this.loaded = true;
        //window.$(window).mousemove(this.onMouseMove);
    },
    methods: {
        onBackgroundTextClicked() {
            this.show = !this.show;
        },
        onHomeClicked() {
            this.show = true;
        },
        onPreviewMouseOver: function() {
            //this.breeze(window.$(event.currentTarget));
        },
        onPreviewMouseMove: function() {
            //this.breeze(window.$(event.currentTarget));
        },
        onPreviewMouseLeave: function() {

        },
        onMouseMove: function(event) {
            let mX = event.pageX;
            let mY = event.pageY;
            let that = this;

            let windowWidth = window.$(window).width();

            window.$('.project-preview').each(function(index, element) {
                let ele = window.$(element);
                let dist = that.mouseDistance(ele, mX, mY);
                //let scale = 1.3 - dist/(windowWidth * 0.8);
                let angle = 60 * dist/(windowWidth * 0.8);
                ele.find('img').css('transform', 'rotateY(' + angle + 'deg)');
            });
        },
        onPreviewMouseDown: function(event) {
            console.log(event);
        }
    }
}
</script>
